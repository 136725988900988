import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import styles from './GameTable.module.css';
import GameRow from './GameRow';
import { exportToExcel } from './utils';
import PromotionsTable from './PromotionsTable';

const CasinoTable = () => {
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCasino, setSelectedCasino] = useState('All');
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [loading, setLoading] = useState(false);
  const [showGoToTopButton, setShowGoToTopButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [gamesCount, setGamesCount] = useState(0);
  const [selectedCasino2, setSelectedCasino2] = useState(null);

  const setCasino = (casino) => {
    setSelectedCasino(casino);

    if (casino !== 'All') {
      setSelectedCasino2(data.casinos.list.find((c) => c.name === casino));
    } else {
      setSelectedCasino2(null);
    }
  };

  useEffect(() => {
    fetchData();

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (data) {
      filterData();
    }
  }, [selectedCasino, selectedProvider, data]);

  const scrollHandler = () => {
    if (window.scrollY > 300) {
      setShowGoToTopButton(true);
    } else {
      setShowGoToTopButton(false);
    }
  };

  const handleGoToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://egt.opsolutions.ro/data.json');
      const fetchedData = await response.json();
      setData(fetchedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterData = () => {
    let filtered = data.casinos.list;

    if (selectedCasino !== 'All') {
      filtered = filtered.filter((casino) => casino.name === selectedCasino);
    }

    if (selectedProvider !== 'All') {
      filtered = filtered.map((casino) => {
        return {
          ...casino,
          data: {
            ...casino.data,
            games: {
              ...casino.data.games,
              list: casino.data.games.list.filter(
                (game) => game.provider.name === selectedProvider
              ),
            },
          },
        };
      });
    }

    if (selectedCasino === 'All') {
      setGamesCount(
        data.casinos.list.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.data.games.count,
          0
        )
      );
    } else {
      const casino = data.casinos.list.find(
        (casino) => casino.name === selectedCasino
      );
      if (selectedProvider === 'All') {
        setGamesCount(casino.data.games.count);
      } else {
        const provider = casino.data.providers.find(
          (provider) => provider.name === selectedProvider
        );
        setGamesCount(provider.games.count);
      }
    }

    setFilteredData(filtered);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(
    filteredData.reduce(
      (total, casino) => total + casino.data.games.list.length,
      0
    ) / rowsPerPage
  );

  const uniqueProviders = data
    ? Array.from(
        new Set(
          data.casinos.list.flatMap((casino) =>
            casino.data.games.list.map((game) => game.provider.name)
          )
        )
      )
    : [];

  const casinoOptions = data
    ? ['All', ...data.casinos.list.map((casino) => casino.name)]
    : [];
  const providerOptions = data ? ['All', ...uniqueProviders] : [];

  const flattenedData = filteredData.flatMap((casino) =>
    casino.data.games.list.map((game) => ({ casino, game }))
  );

  const prepareDataForExport = () => {
    return flattenedData.map(({ casino, game }) => ({
      casino: casino.name,
      game: game.name,
      rtp: game.rtp,
      lines: game.lines,
      provider: game.provider.name,
      categories: game.categories.join(','),
    }));
  };

  const handleExportClick = () => {
    const data = prepareDataForExport();
    exportToExcel(data, 'casino_games_data.xlsx');
  };

  const handleCountsExportClick = () => {
    let exportData = [];
    if (selectedCasino === 'All') {
      data.casinos.list.forEach((casino) => {
        const rowData = {
          casino: casino.name,
        };
        casino.data.providers.forEach((provider) => {
          rowData[provider.name] = provider.games.count;
        });

        exportData.push(rowData);
      });
    } else {
      const casino = data.casinos.list.find(
        (casino) => casino.name === selectedCasino
      );
      const rowData = {
        casino: selectedCasino,
      };
      casino.data.providers.forEach((provider) => {
        rowData[provider.name] = provider.games.count;
      });
      exportData.push(rowData);
    }
    exportToExcel(exportData, 'casino_games_counts.xlsx');
  };

  // The exported file will now have the data only from the current page and the file name will also include the current page number (if needed use this other function)
  //     const handleExportClick = () => {
  //     const currentPageData = flattenedData.slice(
  //       (currentPage - 1) * rowsPerPage,
  //       currentPage * rowsPerPage
  //     );
  //     const data = prepareDataForExport(currentPageData);
  //     exportToExcel(data, `casino_games_data_page_${currentPage}.xlsx`);
  //   };

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={styles.filtersAndExport}>
        <div className={styles.filters}>
          <div>
            <div className={styles.filterName}>Casino</div>
            <select
              value={selectedCasino}
              onChange={(e) => setCasino(e.target.value)}
            >
              {casinoOptions.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className={styles.filterName}>Provider</div>
            <select
              value={selectedProvider}
              onChange={(e) => setSelectedProvider(e.target.value)}
            >
              {providerOptions.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className={styles.filtersName}>Games count</div>
            <div className={styles.filtersCount}>
              <span>{gamesCount}</span>
              <button onClick={handleCountsExportClick}>Export</button>
            </div>
          </div>
        </div>
        <div className={styles.export}>
          <button onClick={handleExportClick}>Export to Excel</button>
        </div>
      </div>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Casino</th>
            <th>Game</th>
            <th>RTP</th>
            <th>Lines</th>
            <th>Image</th>
            <th>Provider</th>
            <th>Categories</th>
          </tr>
        </thead>
        <tbody>
          {flattenedData
            .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
            .map(({ casino, game }, index) => (
              <GameRow
                key={`${game.name}-${index}`}
                casino={casino}
                game={game}
              />
            ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <div>
          <label htmlFor="rows-per-page">Rows per page:</label>
          <select
            id="rows-per-page"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            First
          </button>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            Last
          </button>
        </div>
      </div>

      {selectedCasino2 && selectedCasino2.data.promotions.list.length > 0 && (
        <PromotionsTable casino={selectedCasino2} />
      )}

      {showGoToTopButton && (
        <Button className={styles.goToTopButton} onClick={handleGoToTopClick}>
          <i className={`fa fa-arrow-up ${styles.goToTopIcon}`} />
        </Button>
      )}
    </>
  );
};

export default CasinoTable;
